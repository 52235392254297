.supper-avatar {
    width: 150px;
    height: 150px;
    min-width: 150px;
    border-radius: 50%;
}

.big-avatar {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 50%;
}
.big-rounded-avatar {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 10px;
}

.medium-avatar {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 50%;
}

.small-avatar {
    width: 22px;
    height: 22px;
    min-width: 22px;
    border-radius: 50%;
}