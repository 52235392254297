/* .add_group_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: #0008;
  z-index: 9;
  height: 100vh;
}

.add_group {
  max-width: 750px;
  overflow: auto;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  margin: 20px auto;
  background-color: #d8e7fd;
  height: 90vh;
  overflow: scroll;
} */
.add_group_form {
  position: relative;
}
.add_group .info_avatar {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  margin: 15px auto;
  border: 1px solid #ddd;
  cursor: pointer;
}
.add_group .info_avatar img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.add_group .info_avatar span {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 50%;
  text-align: center;
  color: orange;
  transition: 0.3s ease-in-out;
  background: #0006;
}
.add_group .info_avatar:hover span {
  bottom: -15%;
}
.add_group .info_avatar #file_up {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
::-webkit-file-upload-button {
  cursor: pointer;
}
