.comment_input {
  display: flex;
  align-items: center;
}
.comment_input input {
  background: #f0e9ff42;

  border: none;
  border-radius: 30px;
  outline: none;
  flex: 1 1;
  overflow: auto;
  padding: 4px 10px;
}

.comment_input .postBtn {
  border: none;
  outline: none;
  background: #3b82f6;
  color: #fff;
  font-weight: 600;
  padding: 2px 12px;
  border-radius: 4px;
}
.comment_input .dropdown-menu {
  transform: translate3d(-120px, -200px, 0px) !important;
}

/* ---------- Comments ---------- */
.comment_display {
  padding: 10px 25px;
}
.comment_card .comment_content {
  background: #eeeeee21;
  padding: 7px;
  border-radius: 10px;
  border-top-left-radius: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment_card .comment_content .readMore {
  cursor: pointer;
  color: #7f5dd3;
}

.comment_card .comment_content .nav-item {
  cursor: pointer;
  opacity: 0;
}
.comment_card .comment_content:hover .nav-item {
  opacity: 1;
}
.comment_card .comment_content .dropdown-menu {
  position: absolute;
  right: 0;
  left: inherit;
}
.comment_card .comment_content .dropdown-item {
  display: flex;
  margin: 10px 0;
}
.comment_card .comment_content textarea {
  width: 100%;
  border: none;
  outline: none;
}
