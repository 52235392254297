.friend-group-list {
}

.friend-group-title {
  color: #3633a8;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.card-footer {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
}
.friend-card {
  height: 350px;
}
.card-img-top {
  height: 180px;
}
.badge {
  color: #007bff;
  font-weight: bold;
}
@media (max-width :780px) {
  .badge {
    display: none;
  }
  .card-footer {
    justify-content: center;
  }
}
