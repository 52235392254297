.sidebar {
	padding: 10px;
	background-color: var(--second-bg);
	border: 0px solid rgba(0,0,0,.1);
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	margin-top: 20px;
	margin-left: auto;
	margin-right: 20px;
	width: 260px;
}
