/* --------- Status ---------- */
.status {
  background: #e0e8e82b;
  padding: 20px;
  border-radius: 8px;
  border: var(--border);
  box-shadow: var(--box-shadow);
}
.status .statusBtn {
  background: #f1f1f1;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #555;
  padding: 0 10px;
  text-align: left;
  margin: 0 5px;
  padding: 0 20px;
}
.status .statusBtn:hover {
  background: #ddd;
}

/* ------------Posts ------ */
/* --------- Post Card Header --------- */
.posts .card {
  background-color: var(--second-bg);
  border-radius: 8px;
  border: var(--border);
  box-shadow: var(--box-shadow);
}
.posts .card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 25px;
}
.posts .card_header .card_name {
  transform: translateY(3px);
}
.posts .card_header .dropdown-menu {
  position: absolute;
  right: 0;
  left: inherit;
}
.posts .card_header .dropdown-item {
  display: flex;
  margin: 5px 0;
}

/* ---------- Post Card Body ---------- */
.posts .card_body-content {
  padding: 0 25px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.posts .card_body-content .readMore {
  color: #7f5dd3;
  cursor: pointer;
}

/* ---------- Post Card Footer ---------- */
.posts .card_icon_menu {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  cursor: pointer;
}
.posts .card_icon_menu i {
  color: #d0a6ff;
  font-size: 18px;
  margin: 10px;
}
.posts .card_icon_menu img {
  width: 18px;
  height: 18px;
  transform: translate(5px, -5px);
  opacity: 0.9;
}

/* ---------- HomeLayout ---------- */
.home_content {
  width: 50%;
  padding-left: 32px;
  padding-right: 32px;
}
.home_left {
  width: 25%;
}
.home_right {
  width: 25%;
}

