.header {
  width: 100%;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0 10px 10px rgba(0,0,0,.05);
  background: var(--header-bg);
  border-bottom: 1px solid rgb(0, 0, 0, 0.125);
}
.header .navbar {
  width: 100%;
  height: 100%;
}
.header .logo h1 {
  font-size: 2rem;
}

/* ----------- Header Menu ---------- */
.header .menu li {
  opacity: 0.5;
}
.header .menu li.active {
  opacity: 1;
}
.header .menu .material-icons {
  font-size: 30px;
  color: var(--main-text);
}
.header .menu .dropdown-menu {
  position: absolute;
  left: inherit;
  right: 0;
}
.header .menu label {
  cursor: pointer;
}

.header .menu .notify_length {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: 10px;
}

/* ---------- Header Search -------- */
.header .search_form {
  position: relative;
}
.header .search_form #search {
  background: #ffffff;
  border: 1px solid #ddd;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 420px;
  height: 40px;
  width: 100%;
  outline: none;
  text-indent: 5px;
  border-radius: 50px;
}
.header .search_form .search_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  pointer-events: none;
}
.header .search_form .search_icon .material-icons {
  font-size: 18px;
  transform: translateY(3px);
}
.header .search_form .close_search {
  position: absolute;
  top: 43%;
  right: 5px;
  font-size: 24px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-weight: 900;
  color: crimson;
}
.header .search_form .users {
  position: absolute;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  min-width: 250px;
  background: #ffffff;
  max-height: calc(100vh - 150px);
  overflow: auto;
  margin-top: 3px;
}
.header .search_form .loading {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
}
