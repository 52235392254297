.message {
  width: 100%;
  height: calc(100vh - 100px);
  margin-top: 15px;
  border-radius: 3px;
  background: #ffffff1a;
}

.message_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
}
.message_header input {
  flex: 1 1;
  border: none;
  outline: none;
  color: #222;
  background: #ffffffcc;
  border-radius: 999px;
  padding: 4px 10px;
}
.message_chat_list {
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
}
.message_chat_list .message_user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.6rem;
  color: #555;
  background-color: transparent;
  cursor: pointer;
}
.message_chat_list .message_user.active {
  background: #ffffff1a;
}

.message_chat_list .message_user a {
  color: #555;
}
.message_chat_list .message_user a:hover {
  text-decoration: none;
}

.message_chat_list .message_user .fa-circle {
  font-size: 8px;
  color: #aaa;
}
.message_chat_list .message_user .fa-circle.active {
  color: forestgreen;
}

/* -------- Chat Input----- */
.chat_input {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.chat_input input {
  width: 100%;
  border: none;
  outline: none;
  color: #222;
  background: #ffffffcc;
  border-radius: 999px;
  padding: 4px 10px;
}
.chat_input button {
  border: none;
  outline: none;
  background: transparent;
  color: #dabeff;
  cursor: pointer;
  padding: 0.2rem;
}

.chat_input .nav-link {
  padding: 0 0.2rem;
}

.chat_input .file_upload {
  position: relative;
  overflow: hidden;
  padding: 0 0.2rem;
  font-size: 1.2rem;
}
.chat_input .file_upload #file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.show_media {
  width: 100%;
  height: 70px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  place-items: center;
  grid-gap: 10px;
  background: #f3f3f3;
  border-radius: 5px;
  padding: 0 5px;
}
.show_media #file_media {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 70px;
  max-height: 70px;
}
.show_media #file_media img,
.show_media #file_media video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
.show_media #file_media span {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;

  border: 1px solid crimson;
  padding: 3px 7px;
  color: crimson;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
}

/* -------- Chat Container ----- */
.chat_container {
  width: 100%;
  height: calc(100% - 110px);
  overflow-y: auto;
  padding: 0 10px;
}
.chat_display {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.chat_row {
  display: grid;
  grid-template-columns: 70%;
  margin-bottom: 10px;
}
.you_message {
  justify-content: end;
  justify-items: end;
}
.other_message {
  justify-content: start;
  justify-items: start;
}
.chat_text {
  padding: 9px 14px;
  margin-bottom: 5px;
}
.chat_time {
  font-size: 13px;
  color: #777;
}
.you_message .chat_text {
  background-image: linear-gradient(to top, #0ea5e9, #85C1E9);
  color: #fff;
  border-radius: 14px 14px 0 14px;
}
.other_message .chat_text {
  color: #111;
  background-color: #F2F4F4;
  border: 1px solid #ddd;
  border-radius: 14px 14px 14px 0;
}
.chat_title {
  margin-bottom: 3px;
  color: #ddd;
}
.chat_title > span {
  margin-left: 6px;
}
.you_content {
  position: relative;
}
.you_content .fa-trash {
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
  opacity: 0;
}
.you_content:hover .fa-trash {
  opacity: 1;
}
.chat_input .dropdown-menu {
  transform: translate3d(-110px, -200px, 0px) !important;
}
