.post_thumb {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  grid-gap: 10px;
  overflow: hidden;
  margin: 15px 0;
}
.post_thumb_display {
  min-width: 300px;
  height: 300px;
  width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.post_thumb_display img,
.post_thumb_display video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.post_thumb_display .post_thumb_menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0008;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.3s;
}
.post_thumb_display:hover .post_thumb_menu {
  opacity: 1;
}
.post_thumb_display .post_thumb_menu i {
  font-size: 1.8rem;

  margin: 0 25px;
}
